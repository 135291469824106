<template>
    <div class="">
        <div class="topic-quiz-component">
            <div class="topic">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4 icon d-flex justify-content-center align-content-center" v-if="!isSubTopic">
                        <img v-if="iconExists" :src="iconLink" alt="">
                        <img v-else :src="icon" :alt="title"/>
                    </div>
                    <div class="title" :class="{ 'col-sm-8 col-md-8 col-lg-8': !isSubTopic, 'col-sm-12 col-md-12 col-lg-12': isSubTopic }" style="max-width: 100%;">
                        <template v-if="hasChildren || hasSummaries || totalQuestions >= 12">
                            <h4 @click.prevent="loadTopicDetails" :title="title">{{title}}</h4>
                            <span class="bookmark">
                                <i @click.prevent="bookmarkTopic" class="fa-bookmark" :class="bookmarked ? 'fa-solid' : 'fa-regular'" aria-hidden="true"></i>
                            </span>
                        </template>
                        <template v-else>
                            <h4 style="opacity: 0.6; cursor: not-allowed;" :title="title">{{title}}</h4>
                        </template>
                    </div>
                </div>
                <br/>
                <div class="row stats">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                        <span class="questions">{{totalQuestions}} Fragen</span>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8 right">
                        <span class="answered">{{answered}}</span> <span> beantwortet</span> <span class="percentage">{{ correctPercentage }}%</span> <span>richtig</span>
                    </div>
                </div>


                <div class="progress-container">
                    <div class="progress">
                        <div
                            class="progress-bar-correct"
                            role="progressbar"
                            :style="`width: ${correctPercentage}%`"
                            :aria-valuenow="correctPercentage"
                            :aria-valuemin="0"
                            :aria-valuemax="100"
                        ></div>
                        <div
                            class="progress-bar-answered"
                            role="progressbar"
                            :style="`width: ${incorrectPercentage}%`"
                            :aria-valuenow="incorrectPercentage"
                            :aria-valuemin="0"
                            :aria-valuemax="100"
                        ></div>
                    </div>
                </div>

                <div class="mt-2 mb-2" v-if="premiumQuestions !== null">
                    <span class="tag tag-secondary mt-1">
                        Premium: {{ premiumQuestions }} Fragen
                    </span>
                </div>

                <div class="row links mt-2">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <a
                            href="#"
                            @click.prevent="loadSubtopics"
                            class="button"
                            :class="{'disabled-link': !hasChildren}"
                        >
                            Unterthemen
                            <i class="fa-solid fa-arrow-right"></i>
                        </a>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 right">
                        <router-link
                            class="button"
                            :class="{'disabled-link': totalQuestions < 12}"
                            :to="{ name: 'topics.quiz', params: { topic: topic.id } }"
                        >
                            Übung starten
                            <i class="fa-solid fa-arrow-right solid"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
            <topic-quiz-component :title="'Topic One'"
                                  :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"
                                  :totalQuestions="200"
                                  :answered="10"
                                  :correct="2"
            />
            <topic-quiz-component :title="'Topic Two'"
                                  :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"
                                  :totalQuestions="150"
                                  :answered="15"
                                  :correct="5"
            />
            <topic-quiz-component :title="'Topic Three'"
                                  :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"
                                  :totalQuestions="120"
                                  :answered="120"
                                  :correct="120"
            />

            <topic-quiz-component :title="'Sub Topic'"
                                  :isSubTopic="true"
                                  :totalQuestions="100"
                                  :answered="50"
                                  :correct="25"
            />
        </div>
    </div>
</template>
```
</docs>

<script>

import router from "../router";

/**
 * For displaying quiz topics
 * @displayName TopicQuizComponent
 */
export default {
    name: "TopicQuizComponent",
    props: {
        /**
         * The link to the topic icon. Sub topics will use main topic link.
         * @values url
         */
        icon: {
            type: String,
            required: false
        },
        /**
         * Name/Title of topic or subtopic
         * @values string
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Total number of questions for a topic
         * @values Number
         */
        totalQuestions: {
            type: Number,
            required: true
        },
        premiumQuestions: {
            type: [Number, null],
            default: null,
        },
        /**
         * Total number of questions answered for a topic
         * @values Number
         */
        answered: {
            type: Number,
            required: true
        },
        /**
         * Percentage of questions answered correctly for a topic
         * @values Number
         */
        correct: {
            type: Number,
            required: true
        },
        /**
         * Set true if it is a subtopic and false for main topic
         * @values boolean
         */
        isSubTopic: {
            type: Boolean,
            default: false
        },
        topic: {
            type: Object,
            default: {},
        },
        hasChildren: {
            type: Boolean,
            default: true,
        },
        hasSummaries: {
            type: Boolean,
            default: false,
        },
        bookmarked: {
            type: Boolean,
            default: false
        },
        isTopicDetail: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        async iconExists() {
            try {
                const response = await fetch(this.iconLink, { method: 'HEAD' });
                // Check if the response status is in the 200 range
                return response.ok
            } catch (error) {
                return false
            }
        },
        iconLink() {
            const iconsUrl = 'https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/';
            return iconsUrl + this.title.replace(/ /g, '%20') + '.svg'

        },
        correctPercentage() {
            if (parseInt(this.totalQuestions) === 0)
                return 0;

            return Math.round((this.correct / this.totalQuestions) * 100)
        },
        incorrectPercentage(){
            if (parseInt(this.totalQuestions) === 0)
                return 0;


            return Math.round(((this.answered - this.correct) / this.totalQuestions) * 100);
        }
    },
    components: {

    },
    methods: {
        loadSubtopics() {
            this.$emit('load-hierarchy', this.topic)
        },
        loadTopicDetails() {
            if (this.isTopicDetail) {
                return;
            }

            router.push({path: "/topics/" + this.topic.id + "/details"});

            //this.$emit('load-topic-details', this.topic)
        },
        bookmarkTopic() {
            this.$emit('bookmark-topic', this.topic.id)
        }
    }
}
</script>

<style scoped>

</style>
