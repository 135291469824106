import clip from "text-clipper";

require('./bootstrap');

import {createApp} from 'vue'
import App from './client/app.vue'
import router from './client/router'
import {authStore} from "@/store";
import VueMatomo from 'vue-matomo';
import $ from "jquery";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import 'jquery-ui'
import 'summernote/dist/summernote-lite.min.css'
import 'summernote/dist/summernote-lite.min.js'
import 'summernote/dist/lang/summernote-de-DE.js'
import '@/summernote-ext-specialchars'
import '@admin-css/css/summernote.css'
import '@/summernote-math.js';

const app = createApp({})
import { createI18n } from 'vue-i18n';
import * as Sentry from "@sentry/vue";

Sentry.init({
    app,
    dsn: process.env.MIX_SENTRY_DNS,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [process.env.MIX_APP_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// Register default component globablly
app.component('app', App)
app.use(router)

app.use(authStore)

app.use(VueSweetalert2);

app.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://analytics.deutscher-apotheker-verlag.de/',
    siteId: process.env.MIX_MATOMO_SITE_ID,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    // router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Require consent before creating matomo session cookie
    // Default: false
    requireCookieConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: false,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: [],

    // A function to determine whether to track an interaction as a site search
    // instead of as a page view. If not a function, all interactions will be
    // tracked as page views. Receives the new route as an argument, and
    // returns either an object of keyword, category (optional) and resultsCount
    // (optional) to track as a site search, or a falsey value to track as a page
    // view.
    // Default: false, i.e. track all interactions as page views
    // Example: (to) => {
    //   if (to.query.q && to.name === 'search') {
    //     return { keyword: to.query.q, category: to.params.category }
    //   } else {
    //    return null
    //   }
    // }
    trackSiteSearch: false,

    // Set this to include crossorigin attribute on the matomo script import
    // Default: undefined, possible values : 'anonymous', 'use-credentials'
    crossOrigin: undefined,
});

app.mixin({
    data() {
        return {
            currentZoomBound: null
        }
    },
    methods: {
        handleImageClicks(e) {
            if (e.target.matches("img")) {
                document.getElementById("zoom-image-modal").onclick = function() {
                    $('.modal-backdrop').remove();
                };

                const img = document.getElementById("zoom-image");
                img.src = e.target.src;

                const imageModal = new bootstrap.Modal(document.getElementById("zoom-image-modal"));
                imageModal.show();
            }
        },
        addImageZoom(html) {
            const doc = new DOMParser().parseFromString(html, "text/html");

            Array.from(doc.querySelectorAll("img")).forEach((img) => {
                if (img.src.includes("latex.codecogs.com") === false) {
                    const newElement = new DOMParser().parseFromString('<div class="zoom-image-container"><i class="fa-regular fa-circle-xmark text-primary image-zoom-toggle" style="display: none" @click="disableZoom"></i><div class="zoom" @mousemove="zoomImage" @mouseleave="mouseLeftZoom" @touchmove="zoomImage" @touchstart="zoomStarted" @click="toggleZoom" style="background-image: url(\''+img.src+'\');"><img class="img-zoom" src="'+img.src+'" alt="'+img.src+'"></div></div>', "text/html");
                    img.replaceWith(newElement.body.firstChild);
                }
            });

            return doc.body.innerHTML;
        },
        convertHtmlEntity(html) {
            const span = document.createElement('span');

            return html
                .replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
                    span.innerHTML = entity;
                    return span.innerText;
                });
        },
        removeHTMLTags(str) {
            if ((str===null) || (str===''))
                return false;
            else
                str = str.toString();

            // Regular expression to identify HTML tags in
            // the input string. Replacing the identified
            // HTML tag with a null string.
            return str.replace( /(<([^>]+)>)/ig, '');
        },
        decodeHtml(encodedString) {
            var txt = document.createElement("textarea");
            txt.innerHTML = encodedString;
            return txt.value;
        },
        truncateHtml(htmlString, length = 100) {
            return clip(htmlString, length, { html: true, stripTags: ["img", "svg", "table", "ul", "ol", "br"], maxLines: 1 });
        },
        setCookie(cname, cvalue) {
            var d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        notify(msg = '', status = 'success') {

        },
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(number);
        },
        onClickOutsideZoom(event) {
            if (!event.target.classList.contains("zoom") && !event.target.classList.contains("img-zoom")) {
                this.stopZoomPragmatically();
            }
        },
        onZoomEscapePressed(event) {
            if (event.key === "Escape") {
                this.stopZoomPragmatically();
            }
        },
        stopZoomPragmatically() {
            Array.prototype.forEach.call(document.getElementsByClassName("image-zoom-toggle"), function(el) {
                el.style.display = 'none';
                el.nextElementSibling.classList.remove('zoom-enabled');
                el.nextElementSibling.style.backgroundSize = 'unset';
                el.nextElementSibling.children[0].style.opacity = 'unset';
            });
            this.currentZoomBound = null;
            },
        toggleZoom(e) {
            if (e.target.parentElement.classList.contains('zoom-enabled') === false) {
                e.target.parentElement.classList.add('zoom-enabled')
                e.target.parentElement.previousElementSibling.style.display = 'unset';
                document.addEventListener('keydown', this.onZoomEscapePressed);
                document.addEventListener('click', this.onClickOutsideZoom);

                var zoomer = e.currentTarget;
                zoomer.style.backgroundSize = '200% auto';

                e.target.style.opacity = 0;
                this.currentZoomBound = null;
            }
        },
        disableZoom(e) {
            e.target.style.display = 'none';
            e.target.nextElementSibling.classList.remove('zoom-enabled');
            e.target.nextElementSibling.style.backgroundSize = 'unset';
            e.target.nextElementSibling.children[0].style.opacity = 'unset';
            this.currentZoomBound = null;
        },
        zoomStarted(e) {
            e.target.parentElement.style.backgroundSize = 'unset';
            e.target.style.opacity = 'unset';
            this.currentZoomBound = null;
        },
        zoomImage(e){
            if (e.target.parentElement.classList.contains('zoom-enabled') === false) {
                return;
            }

            e.preventDefault();

            var offsetY, offsetX, x, y, clientY, clientX;
            var zoomer = e.currentTarget;

            if (e.touches !== undefined) {
                var realTarget = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);

                if (realTarget === null) {
                    return;
                }

                if (this.currentZoomBound !== null && realTarget.getBoundingClientRect().y !== this.currentZoomBound.y) {
                    return;
                }

                this.currentZoomBound = {
                    x: realTarget.getBoundingClientRect().x,
                    y: realTarget.getBoundingClientRect().y,
                };

                if (realTarget.getBoundingClientRect().y <= 0 || realTarget.getBoundingClientRect().x <= 0) {
                    if (e.cancelable) {
                        e.preventDefault();
                    }
                    return;
                }

                if ((e.touches[0].clientX - realTarget.getBoundingClientRect().x) <= 0) {
                    if (e.cancelable) {
                        e.preventDefault();
                    }
                    return;
                }

                if ((e.touches[0].clientY - realTarget.getBoundingClientRect().y) <= 1.5) {
                    if (e.cancelable) {
                        e.preventDefault();
                    }
                    return;
                }

                clientX = e.touches[0].clientX - realTarget.getBoundingClientRect().x;
                clientY = e.touches[0].clientY - realTarget.getBoundingClientRect().y;
            }

            offsetX = (e.touches === undefined) ? e.offsetX : clientX;
            offsetY = (e.touches === undefined) ? e.offsetY : clientY;

            x = (offsetX/zoomer.offsetWidth*100);
            y = (offsetY/zoomer.offsetHeight*100);
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
            zoomer.style.backgroundSize = '200% auto';

            e.target.style.opacity = 0;
        },
        mouseLeftZoom(e) {
            if (e.target.classList.contains('zoom-enabled') === false) {
                return;
            }

            var offsetY, offsetX, x, y, clientY, clientX;
            var zoomer = e.currentTarget;

            offsetX = (e.touches === undefined) ? e.offsetX : clientX;
            offsetY = (e.touches === undefined) ? e.offsetY : clientY;

            x = (offsetX/zoomer.offsetWidth*100);
            y = (offsetY/zoomer.offsetHeight*100);
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
            zoomer.style.backgroundSize = '200% auto';

            },
        zoomOutImage(e) {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = 'unset';
        }
    }
})

const numberFormats = {
    'en-GB': {
        currency: {
            style: 'currency', currency: 'GBP'
        },
        currencyNoCents: {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
            maximumFractionDigits: 0
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    }
}

const i18n = createI18n({
    locale: 'en-GB',
    numberFormats
})

app.use(i18n)

app.mount('#app')
