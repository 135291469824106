<template>
    <div class="container-fluid learning-units-container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                </div>
                <div class="row">
                    <div>{{learning_unit.wording}}</div>
                </div>

                <span v-html="learning_unit.css"></span>

                <div class="row" :style="learning_unit.css" v-html="learning_unit.html"></div>
            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";

export default {
    name: "LearningUnit",
    data() {
        return {
            learning_unit: {
                wording: '',
                html: '',
                css: ''
            },
        }
    },
    mounted() {
        this.loadLearningUnit();
    },
    methods: {
        clearData() {
            this.learning_unit.wording = "";
            this.learning_unit.html = "";
            this.learning_unit.css = "";
        },
        loadLearningUnit() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}learning_unit/get/${this.$route.params.id}/view`, {
                headers: headers
            })
                .then(response => {
                    if (response.status === 200) {
                        this.clearData();
                        this.learning_unit.wording = response.data.wording;
                        this.learning_unit.html = response.data.html;
                        this.learning_unit.css = response.data.css;
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {

                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
    },
    components: {

    }
}
</script>

<style scoped>

</style>
