<template>
  <div class="topics-container mt-5">
    <div class="container">
      <div class="row">
        <div class="col">
          <h3>Fächer</h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="breadcrumb">
            <div class="crumbs">
              <span class="crumb" :class="{ active: hierarchy.length <= 0 }" @click="navigateTopic(0)">
                Themen
              </span>
              <template v-for="level in hierarchy">
                &nbsp;
                <i class="fa fa-arrow-right"></i>
                &nbsp;
                <span class="crumb" :class="{ active: isActiveTopic(level.id) }" @click="navigateTopic(level.id, level.level)">
                  {{ level.wording }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="isLoading">
        <preloader />
      </div>
      <template v-else-if="topics.length == 0 && !isTopicDetail">
        <div class="row">
          <div class="col-xs-12 col-xl-4 offset-xl-4 d-flex justify-content-center align-items-center mt-3">
            <div class="no-content-box text-center">
              <div class="title">
                <h4>Wir erarbeiten gerade neue Inhalte für dich.</h4>
                <p>Zurzeit stehen hier leider noch keine Lerninhalte zur Verfügung.</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="isTopicDetail">
        <div class="row">
          <template v-if="topicDetails.id !== undefined" :key="`topic-${topicDetails.id}`">
            <div class="col-sm-12 col-md-6 col-xl-4">
              <topic-quiz-component :title="topicDetails.wording"
                icon="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg"
                :totalQuestions="topicDetails.questions_count"
                :premium-questions="topicDetails.premium_questions_count !== undefined ? topicDetails.premium_questions_count : null"
                :answered="topicDetails.user_performance.attempted" :correct="topicDetails.user_performance.correct" :topic="topicDetails"
                :has-children="topicDetails.children.length > 0" :has-summaries="false" :isTopicDetail="isTopicDetail"
                @load-hierarchy="loadTopicHierarchy" :is-sub-topic="topicDetails.level > 3"
                @load-topic-details="loadTopicDetails" @bookmark-topic="bookmarkTopic" />
            </div>
            <div class="col-sm-12 col-md-6 col-xl-8">
              <div class="row">
                <div class="col-12 col-xl-6"
                  v-if="topicDetails.summary !== null || (topicDetails.summary_count !== null && topicDetails.summary_count > 0)">
                  <topic-connections-component :title="'Zusammenfassung'" icon="Zusammenfassung"
                    :routeName="'topics.summaries'" :topicId="selectedTopicId" />
                </div>
                <div class="col-12 col-xl-6"
                  v-if="topicDetails !== undefined && topicDetails.learning_units_count > 0">
                  <topic-connections-component :title="'Lerneinheit'" icon="Lerneinheit" :routeName="'learning.units'"
                    :topicId="selectedTopicId" />
                </div>
                <div class="col-12 col-xl-6"
                  v-if="topicDetails !== undefined && topicDetails.experiments_count > 0">
                  <topic-connections-component :title="'Experimente'" icon="Experimente" :routeName="'experiments'"
                    :topicId="selectedTopicId" />
                </div>
                <div class="col-12 col-xl-6"
                  v-if="topicDetails !== undefined && topicDetails.collections_count > 0">
                  <topic-connections-component :title="'Übungsklausur'" icon="Übungsklausuren"
                    :routeName="'collections'" :topicId="selectedTopicId" />
                </div>
              </div>
              <!--  -->
            </div>
          </template>
        </div>
      </template>
      <div class="row mt-3" v-else>
        <template v-for="topic in topics" :key="topic.id" v-if="hierarchy.length <= 1">
          <topic-component :title="topic.wording"
            icon="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg" :topic="topic"
            @load-hierarchy="loadTopicHierarchy" />
        </template>
        <template v-else v-for="topic in topics" :key="`topic-${topic.id}`">
          <div class="col-sm-12 col-md-6 col-xl-4">
            <topic-quiz-component :title="topic.wording"
              icon="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg"
              :totalQuestions="topic.questions_count"
              :premium-questions="topic.premium_questions_count !== undefined ? topic.premium_questions_count : null"
              :answered="topic.user_performance.attempted" :correct="topic.user_performance.correct" :topic="topic"
              :has-children="topic.children.length > 0" :is-sub-topic="topic.level > 3"
              :has-summaries="topic.published_summary !== null" :bookmarked="topic.is_bookmarked"
              @load-hierarchy="loadTopicHierarchy" @load-topic-details="loadTopicDetails"
              @bookmark-topic="bookmarkTopic" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { API_URL } from "@/conf.js";
import authHeader from "@/auth.header.js";
import TopicComponent from '@client/components/TopicComponent.vue';
import TopicQuizComponent from '@client/components/TopicQuizComponent.vue';
import TopicSummaryComponent from '@client/components/TopicSummaryComponent.vue';
import TopicConnectionsComponent from '@client/components/TopicConnectionsComponent.vue';
import SummaryComponent from '@client/components/SummaryComponent.vue';
import Preloader from "@client/components/Preloader.vue";
import router from "../router";

export default {
  name: 'topics',
  data() {
    return {
      topic: {},
      topics: [],
      topicDetails: {},
      selectedTopicId: 0,
      hierarchy: [],
      topicSummaries: [],
      isLoading: false,
      isTopicDetail: false,
      isTopicSummaries: false
    }
  },
  beforeMount() {
    if (localStorage.getItem('topics.hierarchy'))
      this.hierarchy = JSON.parse(localStorage.getItem('topics.hierarchy'))
  },
  mounted() {
    this.loadTopics();
  },
  methods: {
    loadTopics() {
      this.isLoading = true
      this.topics = []
      this.topic = {}
      this.topicDetails = {}
      this.selectedTopicId = 0;
      this.topicSummaries = []
      this.isTopicDetail = false
      this.isTopicSummaries = false
      this.hierarchy = [];

      // First determine the hierarchy
      let url = `${API_URL}topic/topics`;

      if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
        url = url + "/" + this.$route.params.id;
      }

      if (this.$route.params.details !== undefined && this.$route.params.details !== "" && this.$route.params.id !== undefined) {
        this.loadTopicDetails({ id: this.$route.params.id });
        return;
      }

      axios.get(url, { 'headers': authHeader() })
        .then(response => {
          if (response.status === 200) {
            this.topics = response.data

            if (this.topics.length > 0 && this.topics[0].level > 1) {
              this.getTopicById(this.topics[0].id);
            }
            /* else {
              router.push({ path: "/topics" });
            } */

            this.isLoading = false
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    /*loadTopicHierarchy(topic) {
        let hierarchyOrder = {
            order: this.hierarchy.length,
            topic: topic,
            topicDetails: false,
        };
        this.hierarchy.push(hierarchyOrder);
        localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy))

        this.isTopicDetail = false;
        this.topicDetails = [];
        this.isTopicSummaries = false;
        this.loadTopics();
    },*/
    loadTopicHierarchy(topic) {
      router.push({ path: "/topics/" + topic.id });
    },
    setHierarchy(hierarchy_order) {
      this.isTopicDetail = false;
      this.topicDetails = {};
      this.isTopicSummaries = false;
      if (!hierarchy_order) this.hierarchy = [];
      else {
        let index = this.hierarchy.length - 1;

        while (index >= 0) {
          if (this.hierarchy[index].order === hierarchy_order) break;

          this.hierarchy.pop()
          index--;
        }
        localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy));
      }
      let index = this.hierarchy.length - 1;
      if (index >= 0 && this.hierarchy[index].topicDetails) {
        this.loadTopicDetails(this.hierarchy[index].topic, false);
      } else {
        this.loadTopics();
      }
    },
    navigateTopic(topicId, level = 0) {
      if (topicId > 0) {
          if (level > 2) {
              router.push({path: "/topics/" + topicId + "/details"});
          } else {
              router.push({ path: "/topics/" + topicId });
          }
      } else {
        router.push({ path: "/topics" });
      }
    },
    getHierarchy() {
      return this.hierarchy;
    },
    getTopicById(topicId) {
      axios.get(`${API_URL}topic/${topicId}`, { 'headers': authHeader() })
        .then(response => {
          if (response.status === 200) {
            this.topic = response.data.topic;
            this.loadAllTopicParents(response.data.topic.id);
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    /*loadTopicDetails(topic, appendHierarchy = true) {
        if (appendHierarchy) {
            let hierarchyOrder = {
                order: this.hierarchy.length,
                topic: topic,
                topicDetails: true,
            };
            this.hierarchy.push(hierarchyOrder);
            localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy))
        }

        this.isTopicSummaries = false;
        this.isTopicDetail = true;
        this.selectedTopicId = topic.id;
        axios.get(`${API_URL}topic/${topic.id}`, { 'headers': authHeader() })
            .then(response => {
                if (response.status === 200) {
                    this.topicDetails.push(response.data.topic);
                }
            })
            .catch(error => {
                console.log(error)
            });
    },*/
    loadTopicDetails(topic) {

      this.isTopicSummaries = false;
      this.isTopicDetail = true;
      this.selectedTopicId = topic.id;
      this.isLoading = true;

      axios
        .get(`${API_URL}topic/${this.$route.params.id}`, { headers: authHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.topicDetails = response.data.topic;
            this.isLoading = false;
            this.loadAllTopicParents(response.data.topic.id);
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    loadAllTopicParents(topicId) {
      axios
        .get(`${API_URL}topic/get_all_parents/${topicId}`, { headers: authHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.hierarchy = response.data;

            // Check if last topic in hierarchy is the current topic for detailed topics, if not add it
            if (this.hierarchy.length > 0 && this.isTopicDetail) {
              if (this.hierarchy[this.hierarchy.length - 1].id !== topicId) {
                this.hierarchy.push(this.topicDetails);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    loadTopicSummaries(topicId) {
      this.isTopicDetail = false;
      this.topicDetails = {};
      this.isTopicSummaries = true;

      axios.get(`${API_URL}topic/children_summaries/${topicId}`, { 'headers': authHeader() })
        .then(response => {
          if (response.status === 200) {
            this.topicSummaries = response.data;
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    isActiveHierarchy(hierarchy) {
      return hierarchy.order === this.hierarchy[this.hierarchy.length - 1].order
    },
    isActiveTopic(topicId) {
      return topicId === this.hierarchy[this.hierarchy.length - 1].id;
    },
    bookmarkTopic(topicId) {
      axios.post(`${API_URL}topic/bookmark`, {
        topic_id: topicId
      }, { 'headers': authHeader() })
        .then(response => {
          if (response.status === 200) {
            this.topics.forEach((topic, index) => {
              if (topicId === topic.id) {
                this.topics[index].is_bookmarked = !topic.is_bookmarked;
              }
            });

            this.$swal("Success", response.data.message, "success");

          } else {
            this.$swal("Error", response.data.message, "error");
          }
        })
        .catch(error => {
          console.log(error)
        });
    }
  },
  components: {
    TopicComponent,
    TopicQuizComponent,
    Preloader,
    TopicSummaryComponent,
    TopicConnectionsComponent,
    SummaryComponent
  },
  watch: {
    $route(to, from) {
      this.loadTopics();
    }
  }
}
</script>
