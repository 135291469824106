<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-sm-6 col-md-4 col-lg-4 mb-5" v-for="topic in selected_topics">
        <router-link
          class="button"
          :to="{ name: 'topics', params: { id: topic.id, details: 'details' } }"
        >
          <topic-component-minimal
            :topic-name="topic.wording"
          />
        </router-link>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div
        class="col d-flex justify-content-center align-items-center load-more-arrow"
      >
        <router-link
          :to="{ name: 'topics' }"
        >
          Alle Fächer &nbsp;
          <i class="fa fa-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import TopicComponentMinimal from '@client/components/TopicComponentMinimal.vue';

export default {
  name: 'PinnedTopics',
  data() {
    return {
      selected_topics: null,
    }
  },
  beforeMount() {
    this.loadSelectedTopics();
  },
  methods: {
    loadSelectedTopics() {
        const headers = authHeader();
        headers['content-type'] = 'application/json'
        axios.get(`${API_URL}user/selected_topics`, {
          headers: headers
        })
        .then(response => {
          if (response.status === 200) {
            this.selected_topics = response.data.selected_topics;
          }  else {
            this.$swal("Error", "Failed to load pinned topics", "error");
          }
        })
        .catch(error => {
          this.$swal("Error", "An Error Occurred. Try again later!", "error");
        });
    },
  },
  components: {
    TopicComponentMinimal,
  }
}
</script>
